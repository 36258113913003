import Vue from 'vue';
import axios from 'axios';
import { Notification } from 'element-ui';

import store from '../store';
import auth from './auth';
import router from '../router';
import { errorTitle, warningTitle } from './messages';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};
axios.defaults.headers = {
  Accept: 'application/json',
};

axios.defaults.withCredentials = true;

if (auth.check()) {
  const token = auth.getToken();
  store.dispatch('ON_LOGIN', token);
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch('SET_LOADING', true);
    store.commit('SET_LOADER_ACTIVE', true);
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch('SET_LOADING', false);
    store.commit('SET_LOADER_ACTIVE', false);
    return response;
  },
  (error) => {
    const { status } = error.response;
    const { data } = error.response;
    if (status === 403) {
      Notification({
        title: warningTitle,
        message: 'No estas autorizado para entrar a esta sección.',
        type: 'warning',
      });
      auth.removeToken();
      router.push({ name: 'Login' });
    } else if (status === 404) {
      if (Object.prototype.hasOwnProperty.call(data, 'message')) {
        Notification({
          title: warningTitle,
          message: data.message || 'Lo que buscas no existe.',
          type: 'warning',
        });
      } else {
        Notification({
          title: warningTitle,
          message: 'Lo que buscas no existe.',
          type: 'warning',
        });
      }
    } else if (status === 500) {
      if (Object.prototype.hasOwnProperty.call(data, 'message')) {
        if (data.message) {
          Notification({
            title: warningTitle,
            message: data.message,
            type: 'warning',
            duration: 0,
          });
        } else {
          Notification({
            title: errorTitle,
            message:
              'Ocurrió un error en nuestros servidores, recargue la pagína e intente de nuevo.',
            type: 'warning',
          });
        }
      }
    } else if (status === 401) {
      auth.removeToken();
      router.push({ name: 'Login' });
    }
    store.dispatch('SET_LOADING', false);
    store.commit('SET_LOADER_ACTIVE', false);
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
