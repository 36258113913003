/* eslint-disable */
import Dashboard from '@/layouts/Dashboard.vue';

const dashboard = [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Home.vue'),
      },
      {
        path: 'users',
        name: 'UsersList',
        component: () => import('@/views/dashboard/users/List.vue'),
      },
      {
        path: 'brands',
        name: 'BrandsList',
        component: () => import('@/views/dashboard/brands/List.vue'),
      },
      {
        path: 'logs',
        name: 'LogsList',
        component: () => import('@/views/dashboard/logs/List.vue'),
      },
      {
        path: 'ubigeo',
        name: 'UbigeoList',
        component: () => import('@/views/dashboard/ubigeo/List.vue'),
      },
      {
        path: 'locals',
        name: 'LocalsList',
        component: () => import('@/views/dashboard/locals/List.vue'),
      },
      {
        path: 'modules',
        name: 'ModulesList',
        component: () => import('@/views/dashboard/modules/List.vue'),
      },
      {
        path: 'modules/:moduleId/sections',
        name: 'ModuleSectionsList',
        component: () => import('@/views/dashboard/modules/sections/List.vue'),
      },
      {
        path: 'modules/:moduleId/:sectionId?/actions',
        name: 'ModuleSectionActionsList',
        component: () => import('@/views/dashboard/modules/actions/List.vue'),
      },
      {
        path: 'roles',
        name: 'RolesList',
        component: () => import('@/views/dashboard/modules/roles/List.vue'),
      },
      {
        path: 'tables/identity-document-types',
        name: 'IdentityDocumentTypesList',
        component: () => import('@/views/dashboard/tables/identity-document-types/List.vue'),
      },
      {
        path: 'tables/catchment-origins',
        name: 'CatchmentOriginsList',
        component: () => import('@/views/dashboard/tables/catchment-origins/List.vue'),
      },
      {
        path: 'tables/digital-channel-states',
        name: 'DigitalChannelStatesList',
        component: () => import('@/views/dashboard/tables/digital-channel-states/List.vue'),
      },
      {
        path: 'tables/payment-methods',
        name: 'PaymentMethodsList',
        component: () => import('@/views/dashboard/tables/payment-methods/List.vue'),
      },
      {
        path: 'tables/kindreds',
        name: 'KindredsList',
        component: () => import('@/views/dashboard/tables/kindreds/List.vue'),
      },
      {
        path: 'tables/financial-entities',
        name: 'FinancialEntitiesList',
        component: () => import('@/views/dashboard/tables/financial-entities/List.vue'),
      },
      {
        path: 'tables/derivative-financial-entities',
        name: 'FinancialEntitiesList',
        component: () => import('@/views/dashboard/tables/derivative-financial-entities/List.vue'),
      },
      {
        path: 'tables/currencies',
        name: 'CurrenciesList',
        component: () => import('@/views/dashboard/tables/currencies/List.vue'),
      },
      {
        path: 'tables/reasons',
        name: 'ReasonsList',
        component: () => import('@/views/dashboard/tables/reasons/List.vue'),
      },
      {
        path: 'tables/tenders/states',
        name: 'CurrenciesList',
        component: () => import('@/views/dashboard/tables/tenders/states/List.vue'),
      },
      {
        path: 'tables/tenders/phases',
        name: 'CurrenciesList',
        component: () => import('@/views/dashboard/tables/tenders/phases/List.vue'),
      },
      {
        path: 'tables/document-types',
        name: 'DocumentTypesList',
        component: () => import('@/views/dashboard/tables/document-types/List.vue'),
      },
      {
        path: 'tables/gps-plans',
        name: 'GpsPlansList',
        component: () => import('@/views/dashboard/tables/gps-plans/ListGps.vue'),
      },
      {
        path: 'settings/backups',
        name: 'Backups',
        component: () => import('@/views/dashboard/settings/backups/Index.vue'),
      },
      {
        path: 'settings/conexos-renovations',
        name: 'Backups',
        component: () => import('@/views/dashboard/settings/conexos-renovations/Index.vue'),
      },
      {
        path: 'settings/release-vehicles',
        name: 'SettingsReleaseVehicles',
        component: () => import('@/views/dashboard/settings/notifications/ReleaseVehicles.vue'),
      },
      {
        path: 'tables/structures',
        name: 'StructuresList',
        component: () => import('@/views/dashboard/tables/structures/List.vue'),
      },
      {
        path: 'tables/benefits',
        name: 'BenefitsList',
        component: () => import('@/views/dashboard/tables/benefits/List.vue'),
      },
      {
        path: 'tables/accesories',
        name: 'AccesoriesList',
        component: () => import('@/views/dashboard/tables/accesories/List.vue'),
      },
      {
        path: 'tables/safes',
        name: 'SafesList',
        component: () => import('@/views/dashboard/tables/safes/List.vue'),
      },
      {
        path: 'tables/units',
        name: 'UnitsList',
        component: () => import('@/views/dashboard/tables/units/List.vue'),
      },
      {
        path: 'tables/categories',
        name: 'CategoriesList',
        component: () => import('@/views/dashboard/tables/categories/List.vue'),
      },
      {
        path: 'tables/prepaid-maintenance',
        name: 'PrepaidMaintenanceList',
        component: () => import('@/views/dashboard/tables/prepaid-maintenance/List.vue'),
      },
      {
        path: 'tables/appointments/motives-list',
        name: 'AppointmentsMotivesList',
        component: () => import('@/views/dashboard/tables/appointments/MotivesList.vue'),
      },
      {
        path: 'tables/jobs',
        name: 'JobsList',
        component: () => import('@/views/dashboard/tables/jobs/List.vue'),
      },
      {
        path: 'tables/checklist/list',
        name: 'CheklistList',
        component: () => import('@/views/dashboard/tables/checklist/ChecklistList.vue'),
      },
      {
        path: 'tables/checklist/vehicle-sections/list',
        name: 'VehicleSectionsList',
        component: () =>
          import('@/views/dashboard/tables/checklist/vehicle-sections/VehicleSectionsList.vue'),
      },
      {
        path: 'tables/banks',
        name: 'BanksList',
        component: () => import('@/views/dashboard/tables/banks/List.vue'),
      },
      {
        path: 'tables/locations',
        name: 'LocationsList',
        component: () => import('@/views/dashboard/tables/locations/List.vue'),
      },
      {
        path: 'tables/insurances',
        name: 'InsurancesList',
        component: () => import('@/views/dashboard/tables/insurances/List.vue'),
      },
      {
        path: 'tables/sale-statuses',
        name: 'TablesSaleStatuses',
        component: () => import('@/views/dashboard/tables/sale-statuses/List.vue'),
      },
      {
        path: 'tables/sale-statuses-parent',
        name: 'TablesSaleStatusesParent',
        component: () => import('@/views/dashboard/tables/sale-statuses-parent/List.vue'),
      },
      {
        path: 'classroom/videos',
        name: 'ClassroomVideosList',
        component: () => import('@/views/dashboard/classroom/videos/List.vue'),
      },
      {
        path: 'messages/send',
        name: 'MessagesSend',
        component: () => import('@/views/dashboard/messages/SendMessage.vue'),
      },
      {
        path: 'quotations/import',
        name: 'QuotationImport',
        component: () => import('@/views/dashboard/quotations/Import.vue'),
      },
      {
        path: 'tables/type-incidents',
        name: 'TypeIncidentsList',
        component: () => import('@/views/dashboard/tables/type-incidents/List.vue'),
      },
      {
        path: 'companies',
        name: 'CompaniesPage',
        component: () => import('@/views/dashboard/companies/List.vue'),
      },
      {
        path: 'nisira/sellers',
        name: 'NisiraSellersPage',
        component: () => import('@/views/dashboard/nisira/sellers/List.vue'),
      },
      {
        path: 'nisira/projects',
        name: 'NisiraProjectsPage',
        component: () => import('@/views/dashboard/nisira/projects/List.vue'),
      },
      {
        path: 'nisira/responsibles',
        name: 'NisiraResponsiblesPage',
        component: () => import('@/views/dashboard/nisira/responsibles/List.vue'),
      },
      {
        path: 'nisira/areas',
        name: 'NisiraAreasPage',
        component: () => import('@/views/dashboard/nisira/areas/List.vue'),
      },
      {
        path: 'nisira/type-uses',
        name: 'NisiraTypeUse',
        component: () => import('@/views/dashboard/nisira/type-uses/List.vue'),
      },
      {
        path: 'warehouses',
        name: 'WarehousePage',
        component: () => import('@/views/dashboard/warehouses/List.vue'),
      },
      {
        path: 'tables/nisira-branches',
        name: 'NisiraBranchesPage',
        component: () => import('@/views/dashboard/tables/nisira-branches/List.vue'),
      },
    ],
  },
];

export default dashboard;
